import React from 'react';

import { VisitorPageHeader } from '../VisitorPageHeader';
import { VisitorPageMedia } from '../VisitorPageMedia';

import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import challengeSettings from '../../../../../settingsParams';
import {
  ImageAlignment,
  VerticalImageAlignment,
  VisitorPageLayout,
} from '../../../../../Settings/challengeSettings/challengeSettings.types';

import { st, classes } from './VisitorPageHeaderBox.st.css';
import { FCWithChildren } from '@wix/challenges-web-library';
import { VisitorPageMediaMobile } from '../VisitorPageMedia/mobile/VisitorPageMedia';

interface IVisitorPageHeaderBoxProps {
  className?: string;
  dataHook?: string;
}

const getHeaderBoxOptions = (experiments, settings) => {
  const isHeaderClassicLayout =
    settings.get(challengeSettings.visitorPageLayout) ===
    VisitorPageLayout.classic;
  const isHeaderSideLayout =
    settings.get(challengeSettings.visitorPageLayout) ===
    VisitorPageLayout.sideBySide;
  const isImageReverse =
    (isHeaderClassicLayout &&
      settings.get(challengeSettings.visitorPageVerticalImageAlignment) ===
        VerticalImageAlignment.top) ||
    (isHeaderSideLayout &&
      settings.get(challengeSettings.visitorPageImageAlignment) ===
        ImageAlignment.left);

  return {
    isHeaderClassicLayout,
    isHeaderSideLayout,
    isImageReverse,
  };
};

export const VisitorPageHeaderBox: FCWithChildren<
  IVisitorPageHeaderBoxProps
> = ({ className, dataHook }) => {
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const settings = useSettings();

  const { isHeaderClassicLayout, isHeaderSideLayout, isImageReverse } =
    getHeaderBoxOptions(experiments, settings);

  const mediaMobile = <VisitorPageMediaMobile />;
  const mediaDesktop = (
    <VisitorPageMedia
      className={classes.media}
      isInline={isHeaderSideLayout && !isMobile}
    />
  );
  const basicHeader = <VisitorPageHeader className={classes.header} />;

  return (
    <div
      className={st(
        classes.root,
        {
          mobile: isMobile,
          type: isHeaderClassicLayout || isMobile ? 'classic' : 'side',
        },
        className,
      )}
      data-hook={dataHook}
    >
      {isImageReverse ? (
        <>
          {isMobile ? mediaMobile : mediaDesktop}
          {basicHeader}
        </>
      ) : (
        <>
          {basicHeader}
          {isMobile ? mediaMobile : mediaDesktop}
        </>
      )}
    </div>
  );
};
