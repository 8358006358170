import React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import challengeSettings from '../../../../../settingsParams';

import { classes, st } from './ChallengeAgendaStepsList.st.css';
import type { FCWithChildren } from '@wix/challenges-web-library';
import type { IChallengeContext } from '../../../../../../../contexts/storage-contexts/Challenge/interfaces';
import { getTitle } from '../../../../../../../selectors/step';
import { StepTypeIcon } from '../../../../../../../components-shared/StepIcon';

export interface IChallengeAgendaStepsListProps {
  className?: string;
  steps: IChallengeContext['challengeSteps'];
}

export const ChallengeAgendaStepsList: FCWithChildren<
  IChallengeAgendaStepsListProps
> = ({ className, steps = [] }: IChallengeAgendaStepsListProps) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const alignment = settings.get(challengeSettings.contentTextAlignment);

  return (
    <ul
      className={`${st(classes.root, {
        mobile: isMobile,
        alignment,
      })} ${className}`}
    >
      {steps.map((step) => {
        return (
          <li className={classes.stepItem} key={step.id}>
            <StepTypeIcon step={step} />
            <span className={classes.stepItemText}>{getTitle(step)}</span>
          </li>
        );
      })}
    </ul>
  );
};

ChallengeAgendaStepsList.displayName = 'ChallengeAgendaStepsList';
ChallengeAgendaStepsList.defaultProps = {
  className: '',
};
